import { Box } from '@mui/material'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

export default function ImpFrame({
    path,
}: {
    path: string | undefined
}): React.ReactElement {
    const { search } = useLocation()
    const [iframeHeight, setIframeHeight] = useState<string>()

    // Catch msg from iframe to set container height to match content for auto resizing
    const handleMessage = (event: MessageEvent) => {
        const { height, type } = event.data
        if (type !== 'CONTENT_HEIGHT_CHANGE') {
            return
        }
        setIframeHeight(height + 'px')
    }

    window.addEventListener('message', handleMessage)
    return (
        <Box
            id="iframe-container"
            sx={{
                overflow: 'hidden',
                height: '600px',
            }}
        >
            <iframe
                className="imp-iframe"
                src={path + search}
                width="100%"
                height="100%"
                id="imp-iframe"
                title="imp-iframe"
            />
        </Box>
    )
}
